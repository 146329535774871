.HomePageHero.dcl.hero {
  height: var(--page-header-height);
}

.HomePageHero .hero-content {
  z-index: -1;
}

.HomePageHero .hero-image {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(../../images/hero.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.HomePageHero.dcl.hero .ui.header.robot-crush {
  font-size: 64px;
  margin-top: 54px;
}

.HomePageHero.dcl.hero .ui.header.dogica {
  font-size: 18px;
  margin-bottom: 14px;
}

.HomePageHero.dcl.hero .ui.header.hero-subtitle {
  margin-bottom: 24px;
  color: var(--secondary-text);
}

.HomePageHero .ui.button.primary {
  background-color: rgb(162, 107, 243);
  box-shadow: rgb(162 107 243) 0px 0px 10px, rgb(162 107 243) 0px 0px 12px;
  /* border-radius: 0px; */
}