.BidPage .ui.header {
  margin-bottom: 8px;
}

.BidPage .subtitle {
  margin-bottom: 32px;
}

.BidPage .form-fields {
  max-width: 420px;
  margin-bottom: 20px;
}

.BidPage .fields .dcl.field {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .BidPage .dcl.field {
    min-width: auto;
  }
}
