.page {
  margin-top: 39px;
}

.badge {
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
}

.firstCell {
  display: flex;
  align-items: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.title {
  font-weight: 600;
}

.ellipsis {
  visibility: hidden;
  color: var(--summer-red);
  cursor: pointer;
}

.row:hover .ellipsis {
  visibility: visible;
}

@media (max-width: 768px) {
  .columnRight {
    margin-top: 12px;
  }
}
