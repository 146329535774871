.Row {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  width: 100%;
}

.Row > .Column.grow {
  flex: 1 1 auto;
}

.Row > .Column.shrink {
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .Row > .Column.right {
    overflow: hidden;
  }
}
