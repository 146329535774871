.AssetImage {
  position: relative;
  height: 100%;
  width: 100%;
}

.AssetImage .pixel {
  display: block;
  width: 100%;
  height: auto;
}

.AssetImage .image-wrapper {
  text-align: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.AssetImage .image {
  margin: auto;
  max-height: 100%;
  z-index: 0;
}

.AssetImage .rarity-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.AssetImage .ens-subdomain {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  height: 100%;
  background-image: url('../../images/logo_dark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--background-secondary);
  color: white;
  flex-direction: column;
}

.AssetImage .ens-subdomain .name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 16px 16px 16px;
}

.AssetImage .ens-subdomain.small .name {
  text-overflow: unset;
  padding: 10px 0px;
  font-size: 60%;
}

.AssetImage .ens-subdomain .monospace {
  font-family: 'Inconsolata', monospace;
  font-size: 17px;
  line-height: 24px;
  margin-top: 12px;
  color: var(--secondary-text);
  padding: 0px 16px 0px 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: lowercase;
}

.AssetImage .WearablePreview {
  position: absolute;
  opacity: 1;
  transition: opacity ease 0.2s;
}

.AssetImage .is-loading-wearable-preview .WearablePreview {
  opacity: 0;
}

.AssetImage .ui.loader.wearable-preview-loader:before {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.AssetImage .ui.loader.wearable-preview-loader:after {
  border-color: white transparent transparent !important;
}

.AssetImage .preview-toggle-wrapper {
  z-index: 1;
  position: absolute;
  right: 24px;
  top: 24px;
}

.AssetImage .preview-toggle-wrapper .preview-toggle {
  width: 40px;
  min-width: 40px !important;
  height: 32px;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: center;
  transform: none !important;
}

.AssetImage .preview-toggle-wrapper .preview-toggle:first-child {
  border-radius: 8px 0px 0px 8px;
}

.AssetImage .preview-toggle-wrapper .preview-toggle:last-child {
  border-radius: 0px 8px 8px 0px;
}

.AssetImage .preview-toggle-wrapper .preview-toggle.preview-toggle-wearable {
  background-image: url(../../images/preview-wearable-icon.svg);
}

.AssetImage .preview-toggle-wrapper .preview-toggle.preview-toggle-avatar {
  background-image: url(../../images/preview-avatar-icon.svg);
}

.AssetImage .preview-toggle-wrapper .preview-toggle.is-active {
  background-color: var(--primary);
}

.AssetImage .preview-toggle-wrapper .preview-toggle.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.AssetImage .preview-toggle-wrapper .preview-toggle.is-disabled:hover {
  background-color: var(--secondary);
}
