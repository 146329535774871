.EstateTags {
  display: flex;
}

.EstateTags .size {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: var(--background-secondary);
  font-size: 12px;
  line-height: 14px;
  color: white;
  padding: 4px 8px;
}
