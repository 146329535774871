.BuyPage .ui.header {
  margin-bottom: 8px;
}

.BuyPage .buttons {
  margin-top: 32px;
}

.BuyPage .error {
  color: var(--danger);
}
