.ENSTags {
  display: flex;
}

.ENSTags .badge {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: var(--background-secondary);
  font-size: 13px;
  text-transform: uppercase;
  line-height: 14px;
  color: white;
  padding: 4px 8px;
}
