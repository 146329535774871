.Highlight.clickable {
  cursor: pointer;
}

.Highlight .left {
  width: 48px;
  height: 48px;
  background-color: var(--background-secondary);
  border-radius: 8px;
}

.Highlight .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.Highlight .name {
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
}

.Highlight .description {
  font-size: 15px;
  line-height: 24px;
  color: var(--secondary-text);
}

.Highlight .left > div {
  width: 100%;
  height: 100%;
}
