.WearableTags {
  display: flex;
}

.WearableTags .rarity {
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 3px 6px;
  border-radius: 4px;
}

.WearableTags .icon {
  margin-left: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 24px;
  height: 24px;
  background-color: var(--background-secondary);
  border-radius: 4px;
}

.WearableTags .icon.body_shape {
  background-image: url(../../../images/wearables/BodyShapeIcon.svg);
}

.WearableTags .icon.earring {
  background-image: url(../../../images/wearables/EarringsIcon.svg);
}

.WearableTags .icon.eyebrows {
  background-image: url(../../../images/wearables/EyebrowIcon.svg);
}

.WearableTags .icon.eyes {
  background-image: url(../../../images/wearables/EyesIcon.svg);
}

.WearableTags .icon.eyewear {
  background-image: url(../../../images/wearables/EyewearIcon.svg);
}

.WearableTags .icon.facial_hair {
  background-image: url(../../../images/wearables/FacilHairIcon.svg);
}

.WearableTags .icon.feet {
  background-image: url(../../../images/wearables/FeetIcon.svg);
}

.WearableTags .icon.hair {
  background-image: url(../../../images/wearables/HairIcon.svg);
}

.WearableTags .icon.hat {
  background-image: url(../../../images/wearables/HatIcon.svg);
}

.WearableTags .icon.helmet {
  background-image: url(../../../images/wearables/HelmetIcon.svg);
}

.WearableTags .icon.lower_body {
  background-image: url(../../../images/wearables/LowerBodyIcon.svg);
}

.WearableTags .icon.mask {
  background-image: url(../../../images/wearables/MaskIcon.svg);
}

.WearableTags .icon.mouth {
  background-image: url(../../../images/wearables/MouthIcon.svg);
}

.WearableTags .icon.tiara {
  background-image: url(../../../images/wearables/TiaraIcon.svg);
}

.WearableTags .icon.top_head {
  background-image: url(../../../images/wearables/TopHeadIcon.svg);
}

.WearableTags .icon.upper_body {
  background-image: url(../../../images/wearables/UpperBodyIcon.svg);
}

.WearableTags .icon.skin {
  background-image: url(../../../images/wearables/SkinIcon.svg);
}

.WearableTags .dcl.smart-icon {
  width: 14px;
  height: 14px;
  margin: 5px;
}
