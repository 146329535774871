.AccountBanner {
  overflow: hidden;
  position: relative;
}

.AccountBanner .ui.container {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
}

.AccountBanner .cover-top {
  display: flex;
  justify-content: space-between;
}

.AccountBanner .icons {
  display: flex;
  gap: 8px;
}

.AccountBanner .icons .icon {
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.AccountBanner .icons .icon.website {
  background-image: url('../../../images/icon-website.svg');
}

.AccountBanner .icons .icon.facebook {
  background-image: url('../../../images/icon-facebook.svg');
}

.AccountBanner .icons .icon.twitter {
  background-image: url('../../../images/icon-twitter.svg');
}

.AccountBanner .icons .icon.discord {
  background-image: url('../../../images/icon-discord.svg');
}

.AccountBanner > .cover {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.AccountBanner > .Column {
  padding: 0 16px;
  position: relative;
}

.AccountBanner .Profile.avatar.huge .avatar-face,
.AccountBanner .Profile.blockie .dcl.blockie {
  width: 96px;
  height: 96px;
}

.AccountBanner .profile-name {
  margin-top: 16px;
  font-size: 28px;
  line-height: 34px;
}

.AccountBanner .profile-address {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.AccountBanner .profile-address-hash {
  margin-right: 5px;
  font-size: 17px;
  line-height: 26px;
}

.AccountBanner .copy {
  cursor: pointer;
}

.AccountBanner .profile-copied-text-desktop {
  position: absolute;
  white-space: nowrap;
}

.AccountBanner .profile-copy-text-mobile {
  margin-top: 5px;
}

.AccountBanner .description {
  margin-top: 12px;
  max-width: 420px;
  font-size: 17px;
  line-height: 26px;
}
