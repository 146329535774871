.mobile-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mobile-row .ui.header.dcl.mana {
  margin: 0px;
}

.mobile-row a {
  color: var(--text);
}
