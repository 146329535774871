.AccountSidebar .ui.sub.header {
  padding-left: 24px;
  padding-top: 17px;
  padding-bottom: 22px;
  margin-bottom: 0px;
}

.AccountSidebar ul.Menu {
  background-color: var(--card);
  border-radius: 10px;
  padding-bottom: 21px;
}

.AccountSidebar ul.Menu > .MenuItem {
  padding-left: 24px;
}

.AccountSidebar ul.Menu > .MenuItem:last-child {
  margin-bottom: 0;
}

.AccountSidebar ul.Menu > .MenuItem.active {
  background-color: var(--background-secondary);
}

.AccountSidebar ul.Menu > .MenuItem.sub-1 {
  margin-left: 0px;
  padding-left: 32px;
  width: var(--menu-item-width);
}

.AccountSidebar ul.Menu > .MenuItem.sub-2 {
  margin-left: 0px;
  padding-left: 48px;
  width: var(--menu-item-width);
}

.AccountSidebar .alternative-menu-item {
  margin: 0 12px;
  padding: 8px 12px;
  margin-bottom: 8px;
}

.AccountSidebar .alternative-menu-item:hover,
.AccountSidebar .alternative-menu-item.selected {
  background-color: var(--background-secondary);
  border-radius: 6px;
  cursor: pointer;
}

.AccountSidebar .alternative-menu-item:last-child {
  margin-bottom: 0px;
}

.AccountSidebar .alternative-menu-item .main-text {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

.AccountSidebar .alternative-menu-item .detail-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: var(--secondary-text);
}
