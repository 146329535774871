.ArrayFilter .options {
  display: flex;
  flex-flow: wrap;
}

.ArrayFilter .options .option {
  padding: 5px 10px;
  background-color: var(--background-secondary);
  border-radius: 16px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
}

.ArrayFilter .options .option:last-child {
  margin-right: 0px;
}

.ArrayFilter .options .option.selected {
  background-color: var(--primary);
}

@media (max-width: 768px) {
  .ArrayFilter {
    margin-bottom: 12px;
  }

  .ArrayFilter .options .option {
    font-size: 13px;
    line-height: 18px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .ArrayFilter .ui.sub.header.name {
    font-weight: normal;
  }
}
