.AssetBrowse .Row .right.Column {
  position: relative;
  max-width: calc(100% - 256px);
}

.AssetBrowse.is-map .Row .right.Column {
  max-width: none;
}

.AssetBrowse .ui.cards {
  position: relative;
}

.AssetBrowse .ui.cards {
  min-height: 150px;
}

.AssetBrowse .ui.cards + .empty {
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  color: var(--secondary-text);
}

.AssetBrowse .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--background);
  opacity: 0.6;
}

.AssetBrowse .load-more {
  text-align: center;
  margin-top: 36px;
  height: 46px;
}

.AssetBrowse .Atlas {
  min-height: 700px;
  height: 100%;
  position: relative;
}

.AssetBrowse .Atlas canvas {
  border-radius: 8px;
}

.AssetBrowse .fullscreen-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(../../images/fullscreen.svg);
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 100%;
  cursor: pointer;
}

.AssetBrowse.fullscreen .Atlas canvas {
  border-radius: 0px;
}

.AssetBrowse.fullscreen .fullscreen-button {
  display: none;
}

.AssetBrowse .topbar {
  margin-bottom: 24px;
}

.AssetBrowse.fullscreen > .Row,
.AssetBrowse.fullscreen > .Row > .Column,
.AssetBrowse.fullscreen .Atlas {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: auto;
  margin-top: 0px;
}
.AssetBrowse.fullscreen .blur-background {
  position: relative;
  background: rgba(22, 20, 26, 0.75);
  backdrop-filter: blur(20px);
  z-index: 1;
}

.AssetBrowse.fullscreen .NFTFilters {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssetBrowse.fullscreen .NFTFilters .topbar {
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
}

.AssetBrowse.fullscreen .NFTFilters .TextFilter {
  margin-bottom: 0px;
}

.AssetBrowse.fullscreen .NFTFilters .TextFilter input {
  padding-bottom: 0px;
}

.AssetBrowse.fullscreen .NFTFilters .TextFilter input::placeholder {
  color: var(--text);
  opacity: 0.5;
}

.AssetBrowse .result-type-toggle {
  margin-bottom: 24px;
  margin-left: -16px;
  margin-right: 24px;
}

@media (max-width: 768px) {
  .AssetBrowse .Row .right.Column {
    max-width: 100%;
  }

  .AssetBrowse .Layout .right.column > div {
    width: 100%;
  }

  .AssetBrowse .NFTSidebar ul.menu li {
    width: calc(100% - 32px);
  }

  .AssetBrowse .NFTFilters .topbar {
    align-items: center;
  }

  .AssetBrowse .result-type-toggle {
    display: none;
  }
}
