.item {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
  margin-bottom: 8px;
}

.item:last-child {
  margin-bottom: 0px;
}

.item:hover {
  background: var(--secondary);
}

.active {
  background: var(--secondary);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled:hover {
  background: none;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #736e7d;
}
