@font-face {
  font-family: 'dogica';
  src: url('./themes/font/dogica.ttf');
}

@font-face {
  font-family: 'robot-crush';
  src: url('./themes/font/robot-crush.ttf');
}

:root {
  --page-header-height: 460px;
  --background: #150a24;
  --background-secondary: #130919e5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dogica {
  font-family: dogica, -apple-system, Arial, Helvetica, sans-serif !important;
  letter-spacing: -2.5px !important;
}

.robot-crush {
  font-family: robot-crush, -apple-system, Arial, Helvetica, sans-serif !important;
  text-shadow: rgb(106 69 160) 0px 0px 1px, rgb(106 69 160) 0px 0px 2px, rgb(106 69 160) 0px 0px 3px, rgb(106 69 160) 0px 0px 4px, rgb(106 69 160) 0px 0px 5px, rgb(106 69 160) 0px 0px 6px !important;
}

.danger-text {
  color: var(--danger);
}

@media (max-width: 768px) {
  .dcl.navbar.overlay .ui.container {
    width: calc(100% - 32px) !important;
  }

  .ui.modal .actions {
    flex-direction: column-reverse;
  }

  .ui.modal .actions .ui.button + .ui.button {
    margin-left: 0px;
  }
}
