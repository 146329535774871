.badge {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
}

.medium {
  padding: 4px 12px;
}
.medium .text {
  font-size: 15px;
}

.small {
  padding: 3px 6px;
  height: 24px;
}
.small .text {
  font-size: 13px;
}

.text {
  color: var(--text);
}
